import React from 'react';
import CustomDialog from 'components/NewLayout/Dialog';
import CustomButton from 'components/NewLayout/Button';
import { Typography, useTheme } from '@mui/material';
import InfoIcon from 'assets/images/info-icon.svg';

export default function DeleteConfirmationModal({
  showModal,
  setShowModal,
  onClickConfirm,
  loader
}: {
  showModal: boolean;
  setShowModal: (s: boolean) => void;
  onClickConfirm: () => void;
  loader: boolean;
}) {
  const theme = useTheme();
  return (
    <CustomDialog
      title={
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '16px' }}>
          <img src={InfoIcon} style={{ marginRight: '10px' }} /> Confirmation
        </Typography>
      }
      open={showModal}
      maxWidth="xs"
      loading={loader}
      closeDialog={() => setShowModal(false)}
      content={
        <Typography>
          Are you sure you want to delete this? You wont be able to undo this action.
        </Typography>
      }
      actions={
        <>
          <CustomButton onClick={() => setShowModal(false)} variant="outlined" label="Discard" />
          <CustomButton onClick={() => onClickConfirm()} label="Confirm" />
        </>
      }
    />
  );
}
