import type { StateCreator } from 'zustand';

export interface TimeTrackingAndAttendenceSlice {
  timeEntries: any;
  setTimeEntries: (data: any) => void;
}

export const timeTrackingAndAttendenceSlice: StateCreator<TimeTrackingAndAttendenceSlice> = (
  set
) => ({
  timeEntries: [],
  setTimeEntries: (timeEntries: any) =>
    set(() => ({
      timeEntries
    }))
});
