import type { StateCreator } from 'zustand';

export type OrderType = {
  id: string | number;
  itemName: string;
  itemQuantity: string;
  itemDate: string;
  unit: string;
  vendor: string;
  status: string;
  project: string;
  description: string;
  image: File | null | Blob;
  imageName: string;
};

export interface CreateOrderSlice {
  orders: OrderType[];
  addOrder: (order: OrderType) => void;
  removeOrder: (id: string | number) => void;
  updateOrder: (order: OrderType) => void;
  removeAllOrders: () => void;
}

export const createOrderSlice: StateCreator<CreateOrderSlice> = (set) => ({
  orders: [],
  addOrder: (order: OrderType) =>
    set((state: CreateOrderSlice) => ({
      orders: [order, ...state.orders]
    })),
  removeAllOrders: () =>
    set(() => ({
      orders: []
    })),
  removeOrder: (id: string | number) =>
    set((state: CreateOrderSlice) => ({
      orders: state.orders.filter((order) => order.id !== id)
    })),
  updateOrder: (order: OrderType) =>
    set((state: CreateOrderSlice) => ({
      orders: state.orders.map((item) => {
        if (item.id === order.id) {
          return {
            ...item,
            itemName: order.itemName,
            itemQuantity: order.itemQuantity,
            itemDate: order.itemDate,
            unit: order.unit,
            vendor: order.vendor,
            status: order.status,
            project: order.project,
            description: order.description,
            image: order.image,
            imageName: order.imageName
          };
        } else {
          return item;
        }
      })
    }))
});
