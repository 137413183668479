import React from 'react';
import { ListItemText } from '@mui/material';
import { ListItemNavButton, NavListItemIcon, renderIcon } from './DashboardLayout';
import { Link } from 'react-router-dom';
import Can from 'components/Can';

const SingleNavItem = ({ menu, open }: { menu: any; open: boolean }) => (
  <Can I="read" module={menu.module} key={menu.name}>
    <Link to={menu.link}>
      <ListItemNavButton key={menu.name}>
        <NavListItemIcon title={!open && menu.name}>{renderIcon(menu.icon)}</NavListItemIcon>
        <ListItemText primary={menu.name} sx={{ display: open ? 'block' : 'none' }} />
      </ListItemNavButton>
    </Link>
  </Can>
);

export default SingleNavItem;
