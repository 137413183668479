import { useEffect, useReducer, useRef, useState } from 'react';
import DropDown from 'components/NewLayout/Dropdown';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import { updatePaymentSchedule } from 'api/index';
import type { BillUpdateForm } from 'api/types';
import Validator from 'simple-react-validator';
import { getValidations } from 'utils/index';
import { useCommonStore, usePaymentWorkspaceStore } from 'store/index';
import CustomDialog from 'components/NewLayout/Dialog';
import CommentsIcon from 'assets/images/comments-icon.svg';
import CustomButton from 'components/NewLayout/Button';
import { Typography } from '@mui/material';

export const allStatus: DropdownOptionType[] = [
  { id: 'Not Approved for Payment', value: 'Not Approved for Payment' },
  { id: 'Approved for Payment', value: 'Approved for Payment' },
  { id: 'Paid', value: 'Paid' }
];

export default function BillUpdateModal({
  billId,
  onClose,
  onComplete,
  purchaseOrderId
}: {
  billId?: number;
  onClose: () => void;
  onComplete?: () => void;
  purchaseOrderId?: string;
}) {
  const [modalShow, setShowModal] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<BillUpdateForm>({ status: '' });
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const updatePaymentScheduleItem = usePaymentWorkspaceStore(
    (state) => state.updatePaymentScheduleItem
  );
  const setSnackBarData = useCommonStore((state) => state.setSnackBarData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    if (billId != 0) {
      setShowModal(true);
    }
  }, []);

  const submitForm = () => {
    if (validator.current.allValid()) {
      setLoader(true);
      updatePaymentSchedule(data, billId!)
        .then((res: { message: string }) => {
          if (res.message === 'Payment Schedule updated successfully!') {
            data.bill_id = billId;
            data.purchase_order_id = purchaseOrderId;
            updatePaymentScheduleItem(data);
            setLoader(false);
            setShowModal(false);
            setSnackBarData({
              type: 'success',
              message: 'Successfully updated Bill Status',
              open: true
            });
            onClose();
            if (onComplete) onComplete();
          }
        })
        .catch((err) => setLoader(false));
      setShowModal(true);
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <CustomDialog
      title={
        <Typography component="div" sx={{ color: '#335d87' }}>
          <img src={CommentsIcon} width="30px" /> Update Bill Status
        </Typography>
      }
      open={modalShow}
      maxWidth="xs"
      closeDialog={() => onClose()}
      loading={loader}
      content={
        <DropDown
          label="Bill Status"
          inputName="status"
          optionText="Select payment method"
          value={data.status}
          onChange={(event) => handleChange(event)}
          options={allStatus}
          required
          validator={validator}
        />
      }
      actions={
        <>
          <CustomButton onClick={() => onClose()} variant="outlined" label="Discard" />
          <CustomButton onClick={submitForm} label="Update Bill" />
        </>
      }
    />
  );
}
