import type {
  GetNetVendors,
  GetOcrPurchaseOrders,
  OcrImportedPurchaseOrderItem,
  PoModalDetails,
  Vendor
} from 'api/types';
import { removeDuplicatesFromArray } from 'utils';
import type { StateCreator } from 'zustand';

export interface ProcurementWorkspaceSlice {
  ocrPurchaseOrders: GetOcrPurchaseOrders[];
  netVendorPurchaseOrders: GetNetVendors[];
  poShipping: PoModalDetails;
  vendorBilling: Vendor[];
  addOcrPurchaseOrder: (orders: GetOcrPurchaseOrders[]) => void;
  addAllOcrPurchaseOrder: (orders: GetOcrPurchaseOrders[]) => void;
  addNetVendorPurchaseOrder: (orders: GetNetVendors[]) => void;
  addAllNetVendorPurchaseOrder: (orders: GetNetVendors[]) => void;
  removeOcrPurchaseOrder: (ocr_imported_purchase_order_draft_id: string) => void;
  removeOcrPurchaseOrderItem: (item: OcrImportedPurchaseOrderItem) => void;
  removeNetVendorItem: (purchase_order_request_item_id: string) => void;
  updateNetVendorPurchaseOrder: (orders: GetNetVendors) => void;
  updateOcrPurchaseOrder: (orders: GetOcrPurchaseOrders) => void;
  addOcrPurchaseOrderItem: (item: OcrImportedPurchaseOrderItem) => void;
  updateOcrPurchaseOrderItem: (item: OcrImportedPurchaseOrderItem) => void;
  updateOcrPurchaseOrderNewItem: (item: OcrImportedPurchaseOrderItem) => void;
  updatePoShipping: (item: PoModalDetails) => void;
  updateVendorBilling: (item: Vendor[]) => void;
}

export const procurementWorkspaceSlice: StateCreator<ProcurementWorkspaceSlice> = (set) => ({
  ocrPurchaseOrders: [],
  netVendorPurchaseOrders: [],
  poShipping: { shipping_cost: '0.00', estimated_tax: '0.00' },
  vendorBilling: [],
  addOcrPurchaseOrder: (orders: GetOcrPurchaseOrders[]) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: removeDuplicatesFromArray(
        [...orders, ...state.ocrPurchaseOrders],
        'ocr_imported_purchase_order_draft_id'
      )
    })),
  addNetVendorPurchaseOrder: (orders: GetNetVendors[]) =>
    set((state: ProcurementWorkspaceSlice) => ({
      netVendorPurchaseOrders: removeDuplicatesFromArray(
        [...orders, ...state.netVendorPurchaseOrders],
        'purchase_order_request_item_id'
      )
    })),
  addAllNetVendorPurchaseOrder: (orders: GetNetVendors[]) =>
    set(() => ({
      netVendorPurchaseOrders: orders
    })),
  addAllOcrPurchaseOrder: (ocrPurchaseOrders: GetOcrPurchaseOrders[]) =>
    set(() => ({
      ocrPurchaseOrders
    })),
  removeOcrPurchaseOrderItem: (purchaseOrderItem: OcrImportedPurchaseOrderItem) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: state.ocrPurchaseOrders.map((orders) => {
        if (
          orders.ocr_imported_purchase_order_draft_id ==
          purchaseOrderItem.ocr_imported_purchase_order_draft_id
        ) {
          const newItem = orders.ocr_imported_purchase_order_items.filter(
            (order) =>
              order.ocr_imported_purchase_order_draft_item_id !==
              purchaseOrderItem.ocr_imported_purchase_order_draft_item_id
          );
          return { ...orders, ocr_imported_purchase_order_items: newItem };
        } else {
          return orders;
        }
      })
    })),
  removeOcrPurchaseOrder: (ocr_imported_purchase_order_draft_id: string) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: state.ocrPurchaseOrders.filter(
        (order) =>
          order.ocr_imported_purchase_order_draft_id !== ocr_imported_purchase_order_draft_id
      )
    })),
  removeNetVendorItem: (purchase_order_request_item_id: string) =>
    set((state: ProcurementWorkspaceSlice) => ({
      netVendorPurchaseOrders: state.netVendorPurchaseOrders.filter(
        (order) => order.purchase_order_request_item_id !== purchase_order_request_item_id
      )
    })),
  updateNetVendorPurchaseOrder: (order: GetNetVendors) =>
    set((state: ProcurementWorkspaceSlice) => ({
      netVendorPurchaseOrders: state.netVendorPurchaseOrders.map((purchaseOrder) => {
        if (purchaseOrder.purchase_order_request_item_id === order.purchase_order_request_item_id) {
          return {
            ...purchaseOrder,
            ...order
          };
        } else {
          return purchaseOrder;
        }
      })
    })),
  updateOcrPurchaseOrder: (order: GetOcrPurchaseOrders) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: state.ocrPurchaseOrders.map((purchaseOrder) => {
        if (
          purchaseOrder.ocr_imported_purchase_order_draft_id ===
          order.ocr_imported_purchase_order_draft_id
        ) {
          return {
            ...purchaseOrder,
            ...order
          };
        } else {
          return purchaseOrder;
        }
      })
    })),
  addOcrPurchaseOrderItem: (purchaseOrderItem: OcrImportedPurchaseOrderItem) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: state.ocrPurchaseOrders.map((orders) => {
        if (
          orders.ocr_imported_purchase_order_draft_id ==
          purchaseOrderItem.ocr_imported_purchase_order_draft_id
        ) {
          const newItem = [...orders.ocr_imported_purchase_order_items, purchaseOrderItem];

          return { ...orders, ocr_imported_purchase_order_items: newItem };
        } else {
          return orders;
        }
      })
    })),
  updateOcrPurchaseOrderItem: (purchaseOrderItem: OcrImportedPurchaseOrderItem) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: state.ocrPurchaseOrders.map((orders) => {
        if (
          orders.ocr_imported_purchase_order_draft_id ===
          purchaseOrderItem.ocr_imported_purchase_order_draft_id
        ) {
          const updatedItems = orders.ocr_imported_purchase_order_items.map((item) => {
            if (
              item.ocr_imported_purchase_order_draft_item_id ===
              purchaseOrderItem.ocr_imported_purchase_order_draft_item_id
            ) {
              return {
                ...item,
                ...purchaseOrderItem
              };
            } else {
              return item;
            }
          });

          return { ...orders, ocr_imported_purchase_order_items: updatedItems };
        } else {
          return orders;
        }
      })
    })),
  updateOcrPurchaseOrderNewItem: (purchaseOrderItem: OcrImportedPurchaseOrderItem) =>
    set((state: ProcurementWorkspaceSlice) => ({
      ocrPurchaseOrders: state.ocrPurchaseOrders.map((orders) => {
        if (
          orders.ocr_imported_purchase_order_draft_id ===
          purchaseOrderItem.ocr_imported_purchase_order_draft_id
        ) {
          const updatedItems = orders.ocr_imported_purchase_order_items.map((item, i, row) => {
            if (i + 1 === row.length) {
              return {
                ...item,
                ...purchaseOrderItem
              };
            } else {
              return item;
            }
          });

          return { ...orders, ocr_imported_purchase_order_items: updatedItems };
        } else {
          return orders;
        }
      })
    })),
  updatePoShipping: (poShipping: PoModalDetails) =>
    set(() => ({
      poShipping
    })),
  updateVendorBilling: (vendorBilling: Vendor[]) =>
    set(() => ({
      vendorBilling
    }))
});
