import type { GetPurchaseOrders, PurchaseOrderItem } from 'api/types';
import { removeDuplicatesFromArray } from 'utils';
import type { StateCreator } from 'zustand';

export interface CreatePurchaseOrderSlice {
  nonReceivedPurchaseOrders: GetPurchaseOrders[];
  receivedPurchaseOrders: GetPurchaseOrders[];
  addNonReceivedPurchaseOrders: (order: GetPurchaseOrders[]) => void;
  addAllNonReceivedPurchaseOrders: (order: GetPurchaseOrders[]) => void;
  removeNonReceivedPurchaseOrder: (purchase_order_id: string) => void;
  updateNonReceivedPurchaseOrder: (order: GetPurchaseOrders) => void;
  addReceivedPurchaseOrders: (order: GetPurchaseOrders[]) => void;
  addAllReceivedPurchaseOrders: (order: GetPurchaseOrders[]) => void;
  removeReceivedPurchaseOrder: (purchase_order_id: string) => void;
  updateReceivedPurchaseOrder: (order: GetPurchaseOrders) => void;
  updateNonReceivedPurchaseOrderItem: (item: PurchaseOrderItem) => void;
  updateReceivedPurchaseOrderItem: (item: PurchaseOrderItem) => void;
}

export const createPurchaseOrderSlice: StateCreator<CreatePurchaseOrderSlice> = (set) => ({
  nonReceivedPurchaseOrders: [],
  receivedPurchaseOrders: [],
  addNonReceivedPurchaseOrders: (orders: GetPurchaseOrders[]) =>
    set((state: CreatePurchaseOrderSlice) => ({
      nonReceivedPurchaseOrders: removeDuplicatesFromArray(
        [...orders, ...state.nonReceivedPurchaseOrders],
        'purchase_order_id'
      )
    })),
  addAllNonReceivedPurchaseOrders: (nonReceivedPurchaseOrders: GetPurchaseOrders[]) =>
    set(() => ({
      nonReceivedPurchaseOrders
    })),
  addAllReceivedPurchaseOrders: (receivedPurchaseOrders: GetPurchaseOrders[]) =>
    set(() => ({
      receivedPurchaseOrders
    })),
  removeNonReceivedPurchaseOrder: (purchase_order_id: string) =>
    set((state: CreatePurchaseOrderSlice) => ({
      nonReceivedPurchaseOrders: state.nonReceivedPurchaseOrders.filter(
        (order) => order.purchase_order_id !== purchase_order_id
      )
    })),

  updateNonReceivedPurchaseOrder: (order: GetPurchaseOrders) =>
    set((state: CreatePurchaseOrderSlice) => ({
      nonReceivedPurchaseOrders: state.nonReceivedPurchaseOrders.map((purchaseOrder) => {
        if (purchaseOrder.purchase_order_id === order.purchase_order_id) {
          return {
            ...purchaseOrder,
            ...order
          };
        } else {
          return purchaseOrder;
        }
      })
    })),

  addReceivedPurchaseOrders: (orders: GetPurchaseOrders[]) =>
    set((state: CreatePurchaseOrderSlice) => ({
      receivedPurchaseOrders: removeDuplicatesFromArray(
        [...orders, ...state.receivedPurchaseOrders],
        'purchase_order_id'
      )
    })),

  removeReceivedPurchaseOrder: (purchase_order_id: string) =>
    set((state: CreatePurchaseOrderSlice) => ({
      receivedPurchaseOrders: state.receivedPurchaseOrders.filter(
        (order) => order.purchase_order_id !== purchase_order_id
      )
    })),

  updateReceivedPurchaseOrder: (order: GetPurchaseOrders) =>
    set((state: CreatePurchaseOrderSlice) => ({
      receivedPurchaseOrders: state.receivedPurchaseOrders.map((purchaseOrder) => {
        if (purchaseOrder.purchase_order_id === order.purchase_order_id) {
          return {
            ...purchaseOrder,
            ...order
          };
        } else {
          return purchaseOrder;
        }
      })
    })),

  updateNonReceivedPurchaseOrderItem: (purchaseOrderItem: PurchaseOrderItem) =>
    set((state: CreatePurchaseOrderSlice) => ({
      nonReceivedPurchaseOrders: state.nonReceivedPurchaseOrders.map((order) => {
        if (order.purchase_order_id === purchaseOrderItem.purchase_order_id) {
          const updatedItems = order.subRows?.map((sr) => {
            if (sr.purchase_order_item_id === purchaseOrderItem.purchase_order_item_id) {
              return { ...sr, ...purchaseOrderItem };
            } else {
              return sr;
            }
          });
          return { ...order, subRows: updatedItems };
        } else {
          return order;
        }
      })
    })),

  updateReceivedPurchaseOrderItem: (purchaseOrderItem: PurchaseOrderItem) =>
    set((state: CreatePurchaseOrderSlice) => ({
      receivedPurchaseOrders: state.receivedPurchaseOrders.map((order) => {
        if (order.purchase_order_id === purchaseOrderItem.purchase_order_id) {
          const updatedItems = order.subRows?.map((sr) => {
            if (sr.purchase_order_item_id === purchaseOrderItem.purchase_order_item_id) {
              return { ...sr, ...purchaseOrderItem };
            } else {
              return sr;
            }
          });
          return { ...order, subRows: updatedItems };
        } else {
          return order;
        }
      })
    }))
});
