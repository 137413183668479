import type { Customer, CustomerContactItems } from 'api/types';
import type { StateCreator } from 'zustand';

export interface CustomerSlice {
  allCustomers: Customer[];
  addCustomerRequests: (customers: Customer[]) => void;
  updateCustomerContact: (customerContact: CustomerContactItems) => void;
  updateCustomer: (customer: Customer) => void;
  addCustomerContact: (item: CustomerContactItems) => void;
  removeCustomerContact: (customerContact: CustomerContactItems) => void;
}

export const customerSlice: StateCreator<CustomerSlice> = (set) => ({
  allCustomers: [],
  addCustomerRequests: (allCustomers: Customer[]) =>
    set(() => ({
      allCustomers
    })),
  addCustomerContact: (customerContact: CustomerContactItems) =>
    set((state: CustomerSlice) => ({
      allCustomers: state.allCustomers.map((customer) => {
        if (customer.customer_id == customerContact.customer_id) {
          const newItem = [...customer.customercontacts, customerContact];
          return { ...customer, customercontacts: newItem };
        } else {
          return customer;
        }
      })
    })),
  updateCustomer: (customer: Customer) =>
    set((state) => ({
      allCustomers: state.allCustomers.map((c) =>
        c.customer_id === customer.customer_id ? { ...c, ...customer } : c
      )
    })),
  updateCustomerContact: (customerContact: CustomerContactItems) =>
    set((state: CustomerSlice) => ({
      allCustomers: state.allCustomers.map((customers) => {
        if (customers.customer_id === customerContact.customer_id) {
          const updatedItems = customers?.customercontacts.map((item) => {
            if (item.uuid === customerContact.uuid) {
              return {
                ...item,
                ...customerContact
              };
            } else {
              return item;
            }
          });

          return { ...customers, customercontacts: updatedItems };
        } else {
          return customers;
        }
      })
    })),
  removeCustomerContact: (customerContact: CustomerContactItems) =>
    set((state: CustomerSlice) => ({
      allCustomers: state.allCustomers.map((customers) => {
        if (customers.customer_id === customerContact.customer_id) {
          const newItem = customers?.customercontacts.filter(
            (contact) => contact.uuid !== customerContact.uuid
          );
          return { ...customers, customercontacts: newItem };
        } else {
          return customers;
        }
      })
    }))
});
