import axios from 'axios';
import TokenService from 'api/tokenService';
import configuration from 'config/index';
import { postOauthgetToken } from 'api';
import toast from 'react-hot-toast';
import AWS from 'aws-sdk';

// Create a variable to keep track of whether a token refresh is in progress
let isRefreshing = false;
let refreshSubscribers: any[] = [];

// Function to add a subscriber to the queue
function subscribeToTokenRefresh(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

// Function to process the queue and refresh the token
function onRefreshed(newToken: string) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
  isRefreshing = false;
}

const instance = axios.create({
  baseURL: configuration.apiBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/oauth2/token' && err.response) {
      if ((err.response.status === 403 || err.response.status === 401) && !originalConfig._retry) {
        originalConfig._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const tokenParams = new URLSearchParams();
            tokenParams.append('grant_type', 'refresh_token');
            tokenParams.append('client_id', configuration?.awsClientId);
            tokenParams.append('refresh_token', TokenService.getRefreshToken());
            const tokenRes = await postOauthgetToken(tokenParams, true);

            TokenService.updateAccessToken(tokenRes.access_token);
            onRefreshed(tokenRes.access_token);

            AWS.config.region = configuration?.awsRegion;

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: configuration?.awsIdentityPoolId,
              Logins: {
                [`cognito-idp.${configuration?.awsRegion}.amazonaws.com/${configuration?.awsUserPoolId}`]:
                  tokenRes.id_token
              }
            });

            (AWS.config.credentials as AWS.CognitoIdentityCredentials).get((err) => {
              if (err) {
                console.log(err);
              } else {
                const accessKeyId = AWS?.config?.credentials?.accessKeyId;
                const secretAccessKey = AWS?.config?.credentials?.secretAccessKey;
                const sessionToken = AWS?.config?.credentials?.sessionToken;
                localStorage.setItem('accessKeyId', accessKeyId ?? '');
                localStorage.setItem('secretAccessKey', secretAccessKey ?? '');
                localStorage.setItem('sessionToken', sessionToken ?? '');
              }
            });

            // Retry original request
            originalConfig.headers['Authorization'] = `Bearer ${tokenRes.access_token}`;
            return axios(originalConfig);
          } catch (error) {
            return Promise.reject(error);
          }
        }

        return new Promise((resolve) => {
          subscribeToTokenRefresh((newToken: string) => {
            originalConfig.headers['Authorization'] = `Bearer ${newToken}`;
            resolve(axios(originalConfig));
          });
        });
      }
    }
    toast.error(`${err.name ? err.name + ':' : ''} ${err.response?.data?.error ?? err.message}`, {
      position: 'bottom-left'
    });
    if (err.response.status === 403) {
      toast.remove();
      toast.error(`${err.name ? err.name + ':' : ''} ${err?.message ?? err.error}`, {
        position: 'bottom-left'
      });
      localStorage.clear();
      window.location.href = '/home';
    }
    return Promise.reject(err);
  }
);

export default instance;
