import React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Loader from './Loader';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowX: 'hidden !important'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function CustomDialog({
  open,
  closeDialog,
  title,
  content,
  actions,
  fullScreen,
  maxWidth,
  loading,
  dontDisplayCloseBtn
}: {
  open: boolean;
  closeDialog: () => void;
  title: string | JSX.Element;
  content: JSX.Element;
  actions?: JSX.Element;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  loading?: boolean;
  dontDisplayCloseBtn?: boolean;
}) {
  return (
    <BootstrapDialog
      onClose={() => closeDialog()}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          ' img': {
            marginTop: '-5px',
            marginRight: '10px'
          }
        }}
        id="customized-dialog-title">
        {title}
      </DialogTitle>
      {!dontDisplayCloseBtn && (
        <IconButton
          aria-label="close"
          onClick={() => closeDialog()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main
          }}>
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent dividers>{loading ? <Loader /> : content}</DialogContent>
      {actions && (
        <DialogActions sx={{ background: '#ebeff3', padding: '15px !important' }}>
          {actions}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
