import React from 'react';
import { Button } from '@mui/material';
import type { CSSProperties } from '@mui/material/styles/createMixins';

export default function CustomButton({
  type,
  label,
  onClick,
  disabled,
  tooltipText,
  variant,
  fullWidth,
  sx,
  className,
  endIcon
}: {
  type?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  tooltipText?: string;
  variant?: 'text' | 'contained' | 'outlined';
  fullWidth?: boolean;
  sx?: CSSProperties;
  className?: string;
  endIcon?: any;
}) {
  return (
    <Button
      className={className}
      color={type || 'secondary'}
      disabled={disabled}
      onClick={onClick}
      variant={variant || 'contained'}
      title={tooltipText || ''}
      sx={{ ...sx, marginRight: '5px' }}
      fullWidth={fullWidth}
      endIcon={endIcon}>
      {label}
    </Button>
  );
}
