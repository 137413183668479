import React from 'react';
import { Box, Typography } from '@mui/material';

export default function CautionBar({
  text,
  bgColor,
  onClick
}: {
  text?: string;
  bgColor?: string;
  onClick?: () => void;
}) {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: bgColor,
        color: '#000',
        textAlign: 'center',
        padding: '8px',
        top: 0,
        left: 0,
        cursor: 'pointer'
      }}
      onClick={onClick}>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}
