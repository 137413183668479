import React from 'react';
import type { PropsWithChildren } from 'react';
import { usePermissionStore } from 'store/index';

interface ICanProps {
  I: 'read' | 'create' | 'update' | 'delete';
  module: string;
  displayTextOnly?: string;
}

export default function Can({
  I,
  module,
  children,
  displayTextOnly
}: PropsWithChildren<ICanProps>) {
  return canI(I, module) ? <>{children}</> : <>{displayTextOnly}</> ?? <></>;
}

export const canI = (permissionName: 'read' | 'create' | 'update' | 'delete', module: string) => {
  const permissions = usePermissionStore((state) => state.permissions);
  const userRoles: any = localStorage.getItem('userRoles');

  if (userRoles) {
    const userRolesArr: string[] = userRoles?.split(',');
    const mod = permissions.filter((p) => userRolesArr.indexOf(p.role) >= 0 && p.module === module);

    if (mod.length > 0 && permissionName) {
      return mod.find((m) => m[permissionName] === true) ? true : false;
    }
  }
  return false;
};
