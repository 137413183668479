import { useLocation } from 'react-router-dom';
import { tabTitle } from 'utils/index';
import PaymentScheduleTable from './component/PaymentScheduleTable';
import Can from 'components/Can';
import { useEffect, useState } from 'react';
import { getQBAlerts } from 'api/adminHub';
import { useAdminWorkspaceStore, useCommonStore, usePaymentWorkspaceStore } from 'store/index';
import PaymentScheduleModal from 'pages/ProcurementWorkspace/components/PaymentScheduleModal';
import type { PaymentPoDetails, PaymentSchedule, PaymentSchedulesItems } from 'api/types';
import { getAllPaymentSchedule } from 'api/index';
import { Grid, InputAdornment, SvgIcon } from '@mui/material';
import CustomTextfield from 'components/NewLayout/Textfield';
import { Search } from '@mui/icons-material';
import { QBBadge } from 'pages/AdminHub/Heading';
import QuickbooksAlert from 'pages/AdminHub/QuickbooksAlert';
import CustomButton from 'components/NewLayout/Button';
import PaperContainer from 'components/PaperContainer';

export default function PaymentWorkspace() {
  tabTitle('Payment Workspace');
  const { state } = useLocation();
  const [showCreatePayment, setShowCreatePayment] = useState<boolean>(false);
  const [paymentPoDetails, setPaymentPoDetails] = useState<PaymentPoDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule[]>([]);
  const [allPayments, setAllPayments] = useState<PaymentSchedule[]>([]);
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const setQBAlerts = useAdminWorkspaceStore((state) => state.setQBAlerts);
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);
  const [searchStr, setSearchStr] = useState<string | null>(null);
  const addPaymentsSchedule = usePaymentWorkspaceStore((state) => state.addPaymentsSchedule);
  const [openQBAlerts, setOpenQBAlerts] = useState<boolean>(false);

  useEffect(() => {
    setComponentHeading('Payment Workspace');
    getQBAlerts().then((res) => {
      setQBAlerts(res ?? []);
    });
    fetchAllPayments();
  }, []);

  useEffect(() => {
    if (searchStr !== null) {
      if (searchStr === '') {
        setPaymentSchedule(allPayments);
      } else {
        const result = allPayments.filter((obj) =>
          Object.values(obj).some((val) =>
            val.toString().toLowerCase().includes(searchStr.toLowerCase())
          )
        );
        setPaymentSchedule(result);
        addPaymentsSchedule(result);
      }
    }
  }, [searchStr]);

  const fetchAllPayments = () => {
    setIsLoading(true);
    getAllPaymentSchedule()
      .then((getPaymentSchedule) => {
        if (state?.purchase_order_id) {
          const ps = getPaymentSchedule.data.filter(
            (res: PaymentSchedulesItems) => res.purchase_order_id == state?.purchase_order_id
          );
          const allPayments = ps.map((p) => ({ ...p, subRows: p.paymentschedules }));
          setAllPayments(ps);
          addPaymentsSchedule(allPayments);
          setPaymentSchedule(ps);
          const totalAmount = parseFloat(state?.total_price ?? '0');
          setPaymentPoDetails({
            total_amount: totalAmount,
            paid_amount: 0,
            remaining_amount: totalAmount,
            scheduled_amount: 0,
            po_number: 0,
            vendor_name: ''
          });
        } else {
          const allPayments = getPaymentSchedule.data.map((p) => ({
            ...p,
            subRows: p.paymentschedules
          }));
          setPaymentSchedule(getPaymentSchedule.data);
          setAllPayments(getPaymentSchedule.data);
          addPaymentsSchedule(allPayments);
          const totalAmount = parseFloat(state?.total_price ?? '0');
          setPaymentPoDetails({
            total_amount: totalAmount,
            paid_amount: 0,
            remaining_amount: totalAmount,
            scheduled_amount: 0,
            po_number: 0,
            vendor_name: ''
          });
        }
        setIsLoading(false);
      })
      .catch((error: Error) => {
        console.log(`Name: ${error.name},
      Message: ${error.message},
      Stack: ${error.stack}`);
        setIsLoading(false);
      });
  };

  const billAlerts = qbAlerts.filter((a) => ['CREATE_BILL', 'UPDATE_BILL'].includes(a.action_type));

  return (
    <PaperContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CustomTextfield
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon component={Search} />
                </InputAdornment>
              )
            }}
            name="search"
            labelText="Search"
            placeholder="Search something"
            onChange={(e) => setSearchStr(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ textAlign: 'end', marginBottom: '10px' }}>
          {billAlerts?.length > 0 && (
            <Can I="read" module="QuickBooksAlert">
              <QBBadge count={billAlerts.length} onClickQBAlerts={() => setOpenQBAlerts(true)} />
            </Can>
          )}
          {state?.purchase_order_id && paymentSchedule.length == 0 && (
            <div style={{ float: 'right', marginRight: '10px' }}>
              <CustomButton
                onClick={() => setShowCreatePayment(true)}
                className="admin-workspace-btn admin-workspace-create-btn"
                label={`Create Payment`}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <br />
      <PaymentScheduleTable loading={isLoading} />
      {showCreatePayment && (
        <PaymentScheduleModal
          purchaseOrderId={state?.purchase_order_id}
          selectedPoTotalPrice={state.total_price ?? 0}
          selectedPoOtherBillTotal="0"
          selectedVendorPayment={''}
          paymentPoDetails={paymentPoDetails}
          onClose={() => setShowCreatePayment(false)}
          onComplete={() => fetchAllPayments()}
        />
      )}
      <QuickbooksAlert open={openQBAlerts} setOpen={() => setOpenQBAlerts(false)} openSection={2} />
    </PaperContainer>
  );
}
