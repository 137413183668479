import React, { useState } from 'react';
import { useAdminWorkspaceStore } from 'store/index';
import Visibility from 'assets/images/visibility.svg';
import QBMessageModal from './QBMessageModal';
import EditIcon from 'assets/images/comments-icon.svg';
import { getCustomerById } from 'api/adminHub';
import { RotatingLines } from 'react-loader-spinner';
import DeleteIcon from 'assets/images/delete-icon.svg';
import type { DataTableObjectType } from 'utils/types';
import DataTable from 'components/NewLayout/Table';

export default function CustomerAlerts({
  onClickDelete,
  onEdit
}: {
  onClickDelete: (id: string) => void;
  onEdit: () => void;
}) {
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const [selectedMsg, setSelectedMsg] = useState<any>(null);
  const [showMsgModal, setShowMsgModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const customerAlerts = qbAlerts.filter((a) =>
    ['UPDATE_CUSTOMER', 'CREATE_CUSTOMER'].includes(a.action_type)
  );

  const setSelectedCustomer = useAdminWorkspaceStore((state) => state.setSelectedCustomer);

  const columns: DataTableObjectType<any>[] = [
    {
      prop: 'action_type',
      name: 'Action Type',
      data: 'action_type'
    },
    {
      prop: 'customer_name',
      name: 'Customer Name',
      data: (row) => {
        const body = row.message?.body;
        const actionType = row.action_type;
        return actionType === 'UPDATE_CUSTOMER'
          ? body?.NewName ?? body?.Name ?? ''
          : body?.Name ?? '';
      }
    },
    {
      prop: 'last_updated_at',
      name: 'Last Updated',
      data: 'last_updated_at',
      type: 'date',
      dateFormat: 'date'
    },
    {
      prop: 'action',
      name: 'Action',
      data: (row) => (
        <>
          <img
            src={Visibility}
            width="30px"
            onClick={() => {
              setSelectedMsg(row);
              setShowMsgModal(true);
            }}
            className="img-fluid link-icon"
            alt="Visibility"
            title="View Message Details"
          />
          <img
            src={EditIcon}
            width="30px"
            onClick={() => onClickEdit(row)}
            className="img-fluid link-icon"
            alt="Edit Icon"
            title="Edit Customer"
          />
          <img
            onClick={() => onClickDelete(row?.oneXerp_id)}
            src={DeleteIcon}
            className={'img-fluid link-icon'}
            alt="Del Icon"
          />
        </>
      )
    }
  ];

  const onClickEdit = (message) => {
    setLoading(true);
    getCustomerById(message?.oneXerp_id)
      .then((res) => {
        if (res.uuid) {
          const customer = {
            ...res,
            actionType: res.quickbooks_id ? 'UPDATE_CUSTOMER' : 'CREATE_CUSTOMER'
          };
          setSelectedCustomer(customer);
          localStorage.setItem('customer', JSON.stringify(customer));
          onEdit();
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return loading ? (
    <div style={{ width: '100%', margin: '5% auto', textAlign: 'center' }}>
      <RotatingLines
        strokeColor="grey"
        strokeWidth="2"
        animationDuration="0.99"
        width="60"
        visible={true}
      />
    </div>
  ) : (
    <>
      <DataTable<any> items={customerAlerts} columns={columns} hidePagination={true} />
      {showMsgModal && (
        <QBMessageModal onClose={() => setShowMsgModal(false)} message={selectedMsg} />
      )}
    </>
  );
}
