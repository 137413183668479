import React, { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import 'src/App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RotatingLines } from 'react-loader-spinner';
import Routes from 'routes';
import 'react-phone-input-2/lib/style.css';
import CustomSnackbar from 'components/NewLayout/Snackbar';

const Loader = () => (
  <div style={{ width: '100%', margin: '20% auto', textAlign: 'center' }}>
    <RotatingLines
      strokeColor="grey"
      strokeWidth="2"
      animationDuration="0.99"
      width="60"
      visible={true}
    />
  </div>
);

function App() {
  const queryClient = new QueryClient();
  return (
    <Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes />
        </div>
        <CustomSnackbar />
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: '#2e7d32',
                color: '#fff'
              },
              duration: 5000
            },
            error: {
              style: {
                background: '#ff4b4b',
                color: '#fff'
              },
              duration: 5000
            },
            loading: {
              style: {
                background: '#007FFF',
                color: '#fff'
              },
              duration: 10000
            }
          }}
        />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
