import React, { useState } from 'react';
import { List, ListItemText, Collapse } from '@mui/material';
import { ListItemNavButton, NavListItemIcon, renderIcon } from './DashboardLayout';
import { Link } from 'react-router-dom';
import Can from 'components/Can';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const NestedNavItem = ({ menu, open }: { menu: any; open: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Can I="read" module={menu.module} key={menu.name}>
      <ListItemNavButton onClick={handleIsOpen}>
        <NavListItemIcon title={!open && menu.name}>{renderIcon(menu.icon)}</NavListItemIcon>
        <ListItemText primary={menu.name} sx={{ display: open ? 'block' : 'none' }} />
        <NavListItemIcon>{isOpen ? <ExpandLess /> : <ExpandMore />}</NavListItemIcon>
      </ListItemNavButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {/* <div className="sidenav-vertical-line" /> */}
        <List component="div" disablePadding>
          {menu.subMenus.map((subMenu) => (
            <Can I="read" module={subMenu.module} key={subMenu.name}>
              <Link to={subMenu.link}>
                <ListItemNavButton sx={{ ml: open ? 4 : 1 }}>
                  <NavListItemIcon title={!open && subMenu.name}>
                    {renderIcon(subMenu.icon)}
                  </NavListItemIcon>
                  <ListItemText primary={subMenu.name} sx={{ display: open ? 'block' : 'none' }} />
                </ListItemNavButton>
              </Link>
            </Can>
          ))}
        </List>
      </Collapse>
    </Can>
  );
};

export default NestedNavItem;
