import React, { useEffect, useState } from 'react';
import ChatIconImg from 'assets/images/chat-icon-img.svg';
import ChatArrow from 'assets/images/arrow_warm_up.svg';
import ChatIcon from 'assets/images/bot.svg';
import TextField from './NewLayout/Textfield';
import config from 'config/index';
import { useUserStore } from 'store/index';
import type { UserType } from 'store/useSlice';
import CustomDialog from './NewLayout/Dialog';
import { Box, Button, Grid, Typography } from '@mui/material';

export interface MessageResponse {
  content?: string;
  role?: string;
  sender?: string;
  text?: string;
}

export async function createThread() {
  const response = await fetch('https://api.openai.com/v1/threads', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${config.chatBotAPI}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': `assistants=v1`
    },
    body: JSON.stringify({})
  });

  if (!response.ok) {
    throw new Error('Failed to create thread');
  }

  const data = await response.json();
  return data.id;
}

export async function addMessageToThread(threadId, content) {
  const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${config.chatBotAPI}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': `assistants=v1`
    },
    body: JSON.stringify({
      role: 'user',
      content
    })
  });

  if (!response.ok) {
    throw new Error('Failed to add message to thread');
  }

  const data = await response.json();
  return data;
}

export async function executeRun(threadId) {
  const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${config.chatBotAPI}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': `assistants=v1`
    },
    body: JSON.stringify({
      assistant_id: 'asst_BeneqxJtFFVEFSryJwsT72x3',
      additional_instructions: null,
      tool_choice: null
    })
  });

  if (!response.ok) {
    throw new Error('Failed to execute run');
  }

  const data = await response.json();
  return data;
}

export async function getRunStatus(threadId, runId) {
  const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${config.chatBotAPI}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': `assistants=v1`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to get run status');
  }

  const data = await response.json();
  return data;
}

export async function getLatestMessage(threadId) {
  const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${config.chatBotAPI}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': `assistants=v1`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to get latest message');
  }

  const data = await response.json();
  const assistantMessage = data.data.find((message) => message.role === 'assistant');
  if (assistantMessage) {
    const textContent = assistantMessage.content.find((contentItem) => contentItem.type === 'text');
    if (textContent) {
      return textContent.text.value;
    }
  }
  throw new Error('No assistant message found');
}

export default function ChatModal({ onClose }: { onClose: () => void }) {
  const [loader, setLoader] = useState<boolean>(false);
  const [chat, setChat] = useState<string>('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [threadId, setThreadId] = useState<null | string>(null);
  const [runId, setRunId] = useState(null);
  const [error, setError] = useState(null);
  const [input, setInput] = useState('');
  const users: UserType[] = useUserStore((state) => state.users);

  useEffect(() => {
    // Create a new thread when the component mounts
    if (localStorage?.getItem('chatThread')) {
      setThreadId(localStorage?.getItem('chatThread') ?? null);
    } else {
      const initThread = async () => {
        try {
          const id = await createThread();
          setThreadId(id);
          localStorage.setItem('chatThread', id ?? '');
        } catch (err: any) {
          setError(err.message);
        }
      };
      initThread();
    }
  }, []);

  const handleSend = async () => {
    if (input.trim() && threadId) {
      const userMessage = { text: input, sender: 'user' };
      setMessages([...messages, userMessage]);

      try {
        await addMessageToThread(threadId, input);
        const runData = await executeRun(threadId);
        console.log('Run initiated:', runData); // Log run data
        setRunId(runData.id);

        // Poll the status of the run
        const pollRunStatus = async (threadId, runId) => {
          let isCompleted = false;
          while (!isCompleted) {
            const statusData = await getRunStatus(threadId, runId);
            console.log('Run status:', statusData); // Log status data

            if (statusData.status === 'completed') {
              setIsTyping(false);
              isCompleted = true;
              const botReply = await getLatestMessage(threadId);
              console.log('Bot reply:', botReply); // Log bot reply
              const botMessage = { text: botReply, sender: 'bot' };
              setMessages((prevMessages) => [...prevMessages, botMessage]);
            } else if (statusData.status === 'failed') {
              isCompleted = true;
              throw new Error('Run failed');
            }

            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before polling again
          }
        };

        pollRunStatus(threadId, runData.id);
      } catch (err: any) {
        console.error('Error:', err);
        setError(err.message);
      }

      setInput('');
    }
  };

  return (
    <>
      <CustomDialog
        open={true}
        closeDialog={() => onClose()}
        maxWidth="md"
        title="Chat Bot"
        content={
          <>
            <Box className="chatbox-div">
              {input != '' || messages.length > 0 ? (
                <>
                  <Box className="chatbox-content-div chatbox-messages-div">
                    {messages.length <= 0 ? (
                      <>
                        <Box component="span" className="d-flex chatbox-ml">
                          <img
                            src={ChatIcon}
                            className="img-fluid chat-icon"
                            alt="OneXerp Chat Icon"
                          />
                          <Box component="span" className="chatbox-ml">
                            <Typography className="chatbox-h5-margin">Bot</Typography>
                            <Typography variant="body2">
                              Sure go ahead, ask anything related to oneXerp.
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        {messages.map((message: MessageResponse, index) => (
                          <Box key={index}>
                            <Typography variant="h4">
                              {message?.sender != 'user' ? (
                                <>
                                  <Box component="span" className="d-flex">
                                    <img
                                      src={ChatIcon}
                                      className="img-fluid chat-icon"
                                      alt="OneXerp Chat Icon"
                                    />
                                    <Box component="span" className="chatbox-ml">
                                      <Typography variant="h5" className="chatbox-h5-margin">
                                        Bot
                                      </Typography>
                                      <Typography variant="body2">{message?.text}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box component="span" className="d-flex">
                                    <Box component="span" className="chatbox-user-message">
                                      <Typography variant="h5" className="chatbox-h5-margin">
                                        {users[0]?.name ?? localStorage.getItem('userName')}
                                      </Typography>
                                      <Typography variant="body2">{message?.text}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Typography>
                          </Box>
                        ))}{' '}
                      </>
                    )}
                  </Box>
                  {isTyping && (
                    <Typography variant="body2" className="chatbox-user-message">
                      Bot is typing...
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Box className="chatbox-content-div" sx={{ textAlign: 'center' }}>
                    <img
                      src={ChatIconImg}
                      className="img-fluid data-table-img"
                      alt="Image placeholder"
                    />
                    <Typography variant="h4" className="chatbox-h3" sx={{ mt: 2 }}>
                      How can I help you today?
                    </Typography>
                  </Box>
                </>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (input.trim() !== '') {
                    handleSend();
                    setInput('');
                    setIsTyping(true);
                  }
                }}
                className="d-flex justify-content-center chatbox-height">
                <Grid container sx={{ width: '70%' }}>
                  <Grid item sx={{ width: '85%' }}>
                    <TextField
                      name="chat"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Ask Ai Trainer...."
                      isRequired={false}
                      disabled={isTyping}
                    />
                  </Grid>

                  <Grid item alignItems="stretch" style={{ display: 'flex' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isTyping}
                      type="submit"
                      sx={{ width: '75%', height: '76%', backgroundColor: '#C32051' }}>
                      <img
                        src={ChatArrow}
                        className="img-fluid data-table-img"
                        alt="Image placeholder"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </>
        }
      />
    </>
  );
}
