const config = {
  development: {
    apiBaseUrl: 'https://api.dev.onexerp.com/api/v1',
    assetsUrl: 'https://onexerp-development-assets.s3.amazonaws.com/', // TODO update this to  'https://onexerp-development-assets.s3.us-east-1.amazonaws.com/' once temporary creds are working
    awsS3AssetsBucketName: 'onexerp-development-assets', // TODO Should be onexerp-development-assets. It is NOT public but is accessible via the roles that the temporary credentials from Cognito map to
    awsRegion: 'us-east-1',
    awsCognitoLoginUrl:
      'https://dev-onexerp.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=53498dj7m6k5eftp22p8v45sqi&state=STATE&redirect_uri={redirect_uri}&code_challenge_method=S256&code_challenge=',
    awsCognitoAuthUrl: 'https://dev-onexerp.auth.us-east-1.amazoncognito.com/',
    awsClientId: '53498dj7m6k5eftp22p8v45sqi',
    awsUserPoolId: 'us-east-1_RFw9nq9gb',
    awsIdentityPoolId: 'us-east-1:3b50de01-24d1-42d9-a5ac-77172c777b79',
    chatBotAPI: 'sk-proj-wFHqfNMcBhvDPg35hyDVT3BlbkFJUWtpgZxpSfQlAtBtHpGw',
    chatBotApiKey: 'sk-proj-wFHqfNMcBhvDPg35hyDVT3BlbkFJUWtpgZxpSfQlAtBtHpGw',
    chatBotEndpoint: 'https://api.openai.com/v1/asst_BeneqxJtFFVEFSryJwsT72x3'
  },
  test: {
    apiBaseUrl: 'https://api.test.onexerp.com/api/v1',
    assetsUrl: 'https://onexerp-test-assets.s3.us-east-1.amazonaws.com/',
    awsAccessKeyID: 'AKIA6BRFAU5NLCU5R76T', // I went ahead and made these but we can't deploy to production with this methodology
    awsSecretAccessKey: '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',
    awsS3AssetsBucketName: 'onexerp-test-assets',
    awsRegion: 'us-east-1',
    awsCognitoLoginUrl:
      'https://test-onexerp.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=5psok0euq7c88nog25t5k0eh9g&state=STATE&redirect_uri={redirect_uri}&code_challenge_method=S256&code_challenge=',
    // I went ahead and just replaced the domain portion to keep it the same format of what you have in the development config
    awsCognitoAuthUrl: 'https://test-onexerp.auth.us-east-1.amazoncognito.com/',
    awsClientId: '5psok0euq7c88nog25t5k0eh9g',
    chatBotAPI: 'sk-proj-wFHqfNMcBhvDPg35hyDVT3BlbkFJUWtpgZxpSfQlAtBtHpGw'
  },
  production: {
    apiBaseUrl: 'https://api.onexerp.com/api/v1',
    assetsUrl: 'https://onexerp-prod-assets.s3.us-east-1.amazonaws.com/',
    awsS3AssetsBucketName: 'onexerp-prod-assets',
    awsRegion: 'us-east-1',
    awsCognitoLoginUrl:
      'https://onexerp.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=6oim4oin9g8t8sn5t2sp6bgvu4&state=STATE&redirect_uri={redirect_uri}&code_challenge_method=S256&code_challenge=',
    // I went ahead and just replaced the domain portion to keep it the same format of what you have in the development config
    awsCognitoAuthUrl: 'https://onexerp.auth.us-east-1.amazoncognito.com/',
    awsClientId: '6oim4oin9g8t8sn5t2sp6bgvu4',
    awsUserPoolId: 'us-east-1_hBaZYT38E',
    awsIdentityPoolId: 'us-east-1:1578c701-fccf-42ff-a57c-edae84a30fa3',
    chatBotAPI: 'sk-proj-wFHqfNMcBhvDPg35hyDVT3BlbkFJUWtpgZxpSfQlAtBtHpGw'
  }
};

export default config[process.env.REACT_APP_ENV?.trim() || 'development'];
