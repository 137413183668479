import type { StateCreator } from 'zustand';

export type UserType = {
  id: string;
  name: string;
  email: string;
};

export interface CreateUserSlice {
  users: UserType[];
  addUser: (user: UserType) => void;
  removeUser: (id: string) => void;
  updateUser: (user: UserType) => void;
}

export const createUserSlice: StateCreator<CreateUserSlice> = (set) => ({
  users: [],
  addUser: (user: UserType) =>
    set((state: CreateUserSlice) => ({
      users: [user, ...state.users]
    })),
  removeUser: (id: string | number) =>
    set((state: CreateUserSlice) => ({
      users: state.users.filter((user) => user.id !== id)
    })),
  updateUser: (user: UserType) =>
    set((state: CreateUserSlice) => ({
      users: state.users.map((item) => {
        if (item.id === user.id) {
          return {
            ...item,
            ...user
          };
        } else {
          return item;
        }
      })
    }))
});
