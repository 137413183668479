import React from 'react';
import type { ReactNode } from 'react';
import { Paper } from '@mui/material';

interface Props {
  children: ReactNode;
}

const PaperContainer: React.FC<Props> = ({ children }) => (
  <Paper sx={{ padding: '20px' }} elevation={1}>
    {children}
  </Paper>
);

export default PaperContainer;
