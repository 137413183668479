import React, { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';

export default function MultiSelect({
  options,
  onChange,
  selectedOptions,
  label,
  inputName,
  disabled
}: {
  label: string;
  options: any;
  onChange: any;
  selectedOptions: any;
  inputName: string;
  disabled?: boolean;
}) {
  return (
    <Autocomplete
      multiple
      disableClearable
      options={options}
      getOptionLabel={(option) => option.value}
      value={selectedOptions}
      onChange={(e, v) => onChange({ target: { name: inputName, value: v } })}
      renderInput={(params) => (
        <TextField
          {...params}
          name={inputName}
          variant="outlined"
          label={label}
          placeholder={'Choose...'}
          disabled={disabled}
        />
      )}
    />
  );
}
