import React, { useState } from 'react';
import { Tabs, Box, Tab, Typography } from '@mui/material';

interface ITabsProps {
  tabs: any;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  openSection?: number;
}

interface ITabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ paddingTop: '30px !important' }}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

function TabsComp(props: ITabsProps) {
  const { tabs, openSection, variant } = props;
  const [value, setValue] = useState<number>(openSection || 0);

  return (
    <>
      <Tabs
        value={value}
        onChange={(e, currentStep) => setValue(currentStep)}
        indicatorColor="primary"
        textColor="primary"
        variant={variant}
        sx={{
          marginLeft: '-20px !important',
          marginRight: '-20px !important',
          marginTop: '-20px !important',
          borderBottom: '2px solid #e3e3e3 !important'
        }}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.key}
            sx={{ textTransform: 'uppercase' }}
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel value={value} key={tab.key} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
}

export default TabsComp;
