import { Box, Drawer, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import type { PropsWithChildren } from 'react';
import CancelIcon from 'assets/images/cancel.svg';

export default function DrawerForm({
  open,
  closeDrawer,
  width,
  children,
  actions,
  heading
}: PropsWithChildren<{
  open: boolean;
  closeDrawer: () => void;
  width?: string;
  actions?: JSX.Element;
  heading: string;
}>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Drawer
      open={open}
      anchor="right"
      sx={{
        '> .MuiPaper-root': {
          width: isMobile ? '100%' : width ?? '50%'
        },
        position: 'relative',
        overflow: 'hidden'
      }}
      onClose={() => closeDrawer()}>
      <Box role="presentation">
        <Box
          sx={{
            padding: isMobile ? '10px' : '30px 40px',
            height: 'calc(100vh - 80px)',
            overflowY: 'auto'
          }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography
                component="div"
                sx={{
                  fontSize: isMobile ? '20px !important' : '24px !important',
                  fontWeight: '600'
                }}>
                {heading}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <img
                src={CancelIcon}
                className="img-fluid link-icon"
                onClick={() => closeDrawer()}
                width="25px"
                style={{ marginTop: '2px' }}
              />
            </Grid>
          </Grid>
          {children}
        </Box>
      </Box>
      <Typography
        component="div"
        sx={{
          height: '80px',
          textAlign: 'right',
          padding: '20px',
          backgroundColor: '#ebeff3',
          '> button': {
            marginRight: '10px'
          },
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0
        }}>
        {actions}
      </Typography>
    </Drawer>
  );
}
