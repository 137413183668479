import type { StateCreator } from 'zustand';

type snackBarDataType = {
  message: string;
  open: boolean;
  type: 'success' | 'error' | 'info' | 'warning';
};
export interface CommonSlice {
  componentHeading: string;
  setComponentHeading: (text: string) => void;
  snackBarData: snackBarDataType;
  setSnackBarData: (data: snackBarDataType) => void;
}

export const commonSlice: StateCreator<CommonSlice> = (set) => ({
  componentHeading: '',
  setComponentHeading: (text: string) =>
    set(() => ({
      componentHeading: text
    })),
  snackBarData: {
    open: false,
    message: '',
    type: 'success'
  },
  setSnackBarData: (data: snackBarDataType) =>
    set(() => ({
      snackBarData: data
    }))
});
