import type { PaymentSchedule, PaymentSchedulesItems } from 'api/types';
import type { StateCreator } from 'zustand';

export interface PaymentWorkspaceSlice {
  allPaymentsSchedules: PaymentSchedule[];
  addPaymentsSchedule: (payments: PaymentSchedule[]) => void;
  updatePaymentScheduleItem: (item: PaymentSchedulesItems) => void;
  addPaymentScheduleItem: (item: PaymentSchedulesItems[]) => void;
}

export const paymentWorkspaceSlice: StateCreator<PaymentWorkspaceSlice> = (set) => ({
  allPaymentsSchedules: [],
  addPaymentsSchedule: (allPaymentsSchedules: PaymentSchedule[]) =>
    set(() => ({
      allPaymentsSchedules
    })),
  addPaymentScheduleItem: (paymentScheduleItem: PaymentSchedulesItems[]) =>
    set((state: PaymentWorkspaceSlice) => ({
      allPaymentsSchedules: state.allPaymentsSchedules.map((item) => {
        if (item.purchase_order_id == paymentScheduleItem[0].purchase_order_id) {
          const newItem = [...item.paymentschedules.concat(paymentScheduleItem)]; //[...item.paymentschedules, paymentScheduleItem];
          return { ...item, paymentschedules: newItem };
        } else {
          return item;
        }
      })
    })),
  updatePaymentScheduleItem: (paymentScheduleItem: PaymentSchedulesItems) =>
    set((state: PaymentWorkspaceSlice) => ({
      allPaymentsSchedules: state.allPaymentsSchedules.map((payments) => {
        if (payments.purchase_order_id === paymentScheduleItem.purchase_order_id) {
          const updatedItems = payments?.paymentschedules.map((item) => {
            if (item.bill_id === paymentScheduleItem.bill_id) {
              return {
                ...item,
                ...paymentScheduleItem
              };
            } else {
              return item;
            }
          });

          return { ...payments, paymentschedules: updatedItems };
        } else {
          return payments;
        }
      })
    }))
});
