import AddCardIcon from '@mui/icons-material/AddCard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import type { CSSObject, Theme } from '@mui/material/styles';
import { styled, useTheme } from '@mui/material/styles';
import AdminConsole from 'assets/images/admin-console.svg';
import AnalyticsDashboard from 'assets/images/analytics-dashboard.svg';
import Bot from 'assets/images/bot.svg';
import TimeWSIcon from 'assets/images/chronic.svg';
import CollapsedLogo from 'assets/images/collapsed-logo.svg';
import CreditCard from 'assets/images/creditcard.svg';
import Customer from 'assets/images/customer.svg';
import DriverWorkspace from 'assets/images/driver-workspace.svg';
import EstimateHub from 'assets/images/estimate-hub.svg';
import HelpIcon from 'assets/images/help.png';
import Job from 'assets/images/job.svg';
import LogoutIcon from 'assets/images/logout.svg';
import logo from 'assets/images/onexerp-logo.svg';
import OrderAndLogistics from 'assets/images/order-and-logistic.svg';
import PaymentWorkspace from 'assets/images/payment-workspace.svg';
import People from 'assets/images/people.svg';
import ProcurementWorkspace from 'assets/images/procurement-workspace.svg';
import ProfileIcon from 'assets/images/profile-avatar.png';
import PunchFormIcon from 'assets/images/punch_clock.svg';
import ReceivingWorkspace from 'assets/images/receiving-workspace.svg';
import RequestWorkspace from 'assets/images/request-workspace.svg';
import TimeTrackingAndAttendence from 'assets/images/time-tracking-and-attendence.svg';
import TransportationWorkspace from 'assets/images/transportation-workspace.svg';
import Vehicle from 'assets/images/vehicle.svg';
import Vendor from 'assets/images/vendor.svg';
import CautionBar from 'components/CautionBar';
import ChatModal from 'components/ChatModal';
import config from 'config/index';
import { format } from 'date-fns';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCommonStore } from 'store/index';
import { menus } from './menus';
import NestedNavItem from './NestedNavItem';
import SingleNavItem from './SingleNavItem';
import { getAllVendors } from 'api/adminHub';
import type { Vendor as VendorType } from 'api/types';

const drawerWidth = 286;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme, isMobile: boolean): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: isMobile ? '0' : `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isMobile: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile'
})<AppBarProps>(({ theme, open, isMobile }) => ({
  backgroundColor: '#f4f4f4',
  marginBottom: '50px',
  color: '#000',
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: open
    ? `calc(100% - ${drawerWidth}px)`
    : isMobile
    ? '100%'
    : `calc(100% - ${theme.spacing(7)})`,
  [theme.breakpoints.up('sm')]: {
    width: open
      ? `calc(100% - ${drawerWidth}px)`
      : isMobile
      ? '0'
      : `calc(100% - ${theme.spacing(8)})`
  },
  ...(open && {
    marginLeft: isMobile ? '0' : drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile'
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  backgroundColor: '#f4f4f4',
  textAlign: 'left',
  flexGrow: 1,
  padding: theme.spacing(3),
  width: isMobile ? '0' : `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile'
})<{
  open?: boolean;
  isMobile: boolean;
}>(({ theme, open, isMobile }) => ({
  width: isMobile ? '100%' : drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  paddingBottom: '100px',
  ...(open
    ? {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          ...openedMixin(theme),
          border: 'none',
          paddingBottom: '100px'
        }
      }
    : {
        ...closedMixin(theme, isMobile),
        '& .MuiDrawer-paper': { ...closedMixin(theme, isMobile), border: 'none' }
      })
}));

export const ListItemNavButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '10px',
  border: '2px solid #f4f4f4',
  margin: '8px',
  padding: '8px'
}));

export const NavListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '30px'
}));

const logout = () => {
  const clientId = config?.awsClientId;
  const appUrl = `${window.location.protocol}//${window.location.host}/home`;
  const logoutUri = encodeURIComponent(appUrl);

  localStorage.clear();
  window.location.href = `${config.awsCognitoAuthUrl}logout?&state=STATE&response_type=code&client_id=${clientId}&logout_uri=${logoutUri}&redirect_uri=${logoutUri}`;
};

const Preference = ({ open, setChatModal }: { open: boolean; setChatModal: (res) => void }) => (
  <Box
    sx={{
      marginTop: '100px',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      background: '#fff',
      width: 'inherit',
      height: '300px'
    }}>
    <List
      disablePadding
      subheader={
        <ListSubheader
          sx={{ textAlign: 'left', display: open ? 'block' : 'none' }}
          component="div"
          id="nested-list-subheader">
          PREFERENCES
        </ListSubheader>
      }
      sx={{ display: 'block', position: 'reletive', bottom: 0 }}>
      <ListItemNavButton
        sx={{
          backgroundColor: '#f6dfe6',
          ':hover': {
            backgroundColor: '#f6dfe6'
          }
        }}
        onClick={() => setChatModal(true)}>
        <NavListItemIcon sx={{ marginRight: '5px' }}>
          <img src={Bot} width="30px" />
        </NavListItemIcon>
        <ListItemText
          primary={'I know everything, test me'}
          sx={{ display: open ? 'block' : 'none' }}
        />
      </ListItemNavButton>
      <Link
        to="https://forms.monday.com/forms/12c54837fdf1be5def1a1ef2796720f2?r=use1"
        target="_blank">
        <ListItemNavButton>
          <NavListItemIcon sx={{ marginRight: '5px' }}>
            <img src={HelpIcon} width="30px" />
          </NavListItemIcon>
          <ListItemText primary={'Report an issue'} sx={{ display: open ? 'block' : 'none' }} />
        </ListItemNavButton>{' '}
      </Link>
      <Link
        to="https://miro.com/app/board/uXjVKy5DGh8=/?share_link_id=730712317732"
        target="_blank">
        <ListItemNavButton>
          <NavListItemIcon>
            <NavListItemIcon sx={{ marginRight: '5px' }}>
              <img src={HelpIcon} width="30px" />
            </NavListItemIcon>
          </NavListItemIcon>
          <ListItemText primary={'Help'} sx={{ display: open ? 'block' : 'none' }} />
        </ListItemNavButton>
      </Link>
      <ListItemNavButton onClick={logout}>
        <NavListItemIcon sx={{ marginRight: '5px' }}>
          <img src={LogoutIcon} width="30px" />
        </NavListItemIcon>
        <ListItemText primary={'Log out'} sx={{ display: open ? 'block' : 'none' }} />
      </ListItemNavButton>
    </List>
  </Box>
);

export const renderIcon = (name: string) => {
  let icon = '';
  let width = '30px';
  if (name === 'order-and-logistic') {
    icon = OrderAndLogistics;
  } else if (name === 'request-workspace') {
    icon = RequestWorkspace;
  } else if (name === 'procurement-workspace') {
    icon = ProcurementWorkspace;
  } else if (name === 'ocr-po-workspace') {
    icon = ProcurementWorkspace;
  } else if (name === 'receiving-workspace') {
    icon = ReceivingWorkspace;
  } else if (name === 'payment-workspace') {
    icon = PaymentWorkspace;
  } else if (name === 'transportation-workspace') {
    icon = TransportationWorkspace;
  } else if (name === 'driver-workspace') {
    icon = DriverWorkspace;
  } else if (name === 'time-tracking-and-attendence') {
    icon = TimeTrackingAndAttendence;
  } else if (name === 'estimate-hub') {
    icon = EstimateHub;
  } else if (name === 'admin-console') {
    icon = AdminConsole;
  } else if (name === 'analytics') {
    icon = AnalyticsDashboard;
  } else if (name === 'people-workspace') {
    icon = People;
  } else if (name === 'vendor-workspace') {
    icon = Vendor;
  } else if (name === 'creditcard-workspace') {
    icon = CreditCard;
  } else if (name === 'customer-workspace') {
    icon = Customer;
  } else if (name === 'integration-workspace') {
    icon = Customer;
  } else if (name === 'job-workspace') {
    icon = Job;
  } else if (name === 'vehicle-workspace') {
    icon = Vehicle;
  } else if (name === 'punch-form') {
    icon = PunchFormIcon;
    width = '20px';
  } else if (name === 'time-workspace') {
    icon = TimeWSIcon;
    width = '20px';
  }

  return <img src={icon} width={width} style={{ marginRight: '10px' }} />;
};

interface Props {
  children: ReactNode;
}

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(isMobile ? false : true);
  const [chatModal, setChatModal] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const routeChange = () => {
    console.log('onClick={onClick}');

    const path = `admin-hub/integration-workspace`;
    navigate(path);
  };

  const getLocalStorageItem = (key) => {
    const value = localStorage.getItem(key);
    return value && value !== 'null' ? value : '';
  };

  const preferredName = getLocalStorageItem('preferredName');
  const firstName = getLocalStorageItem('firstName');
  const lastName = getLocalStorageItem('lastName');
  const userName = preferredName ? preferredName : `${firstName} ${lastName}`.trim() || 'User';
  const userRole: string = localStorage.getItem('userRoles') ?? '';
  const componentHeading = useCommonStore((state) => state.componentHeading);
  const [activeInactive, setActiveInactive] = useState<{
    active: string;
    inactive: string;
  }>({
    active: '0',
    inactive: '0'
  });

  useEffect(() => {
    setOpen(isMobile ? false : true);
  }, [isMobile]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getData = () => {
    getAllVendors('', '', '', '', false, 'false')
      .then((res: { data: VendorType[]; activeVendors: string; inactiveVendors: string }) => {
        setActiveInactive({
          active: res.activeVendors,
          inactive: res.data.length
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const isProdEnv = window.location.host === 'onexerp.com';

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} isMobile={isMobile}>
        {!isProdEnv && (
          <>
            <CautionBar
              text="CAUTION: You are in the Dev environment. Changes made here do not affect production."
              bgColor="#FFA726"
            />
            <CautionBar
              text={`There are ${activeInactive.inactive} vendors waiting for approval`}
              bgColor="#23e500"
              onClick={routeChange}
            />
          </>
        )}
        <Toolbar sx={{ justifyContent: 'space-between', padding: '24px', background: '#fff' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 3,
              ...(open && { display: 'none' }),
              backgroundColor: '#fff'
            }}>
            <ChevronRightIcon />
          </IconButton>
          {componentHeading == 'punch form' || componentHeading == 'time workspace' ? (
            <>
              <Typography component="div">
                <Typography
                  sx={{
                    fontWeight: '600 !important',
                    fontSize: isMobile ? '16px !important' : '24px !important'
                  }}>
                  Greetings, {userName}!
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      textAlign: 'left',
                      color: '#335D87',
                      fontSize: '14px',
                      fontWeight: '400'
                    }}>
                    {format(new Date(), 'EEEE dd MMMM, yyyy')}
                  </Typography>
                </Typography>
              </Typography>
              <Typography component="div" sx={{ float: 'right' }}>
                <Link
                  to={
                    componentHeading === 'punch form'
                      ? '/time-tracking-and-attendence/time-workspace'
                      : '/time-tracking-and-attendence/punch-form'
                  }>
                  <Button
                    variant="outlined"
                    startIcon={
                      componentHeading === 'punch form' ? (
                        <img src={TimeWSIcon} width={20} />
                      ) : (
                        <img src={PunchFormIcon} width={15} />
                      )
                    }
                    sx={{
                      color: '#335D87',
                      border: '0',
                      backgroundColor: '#335D871A',
                      borderRadius: '29px',
                      textTransform: 'math-auto'
                    }}>
                    <Typography sx={{ fontSize: '16px !important' }}>
                      {componentHeading === 'punch form'
                        ? 'Navigate to Time Workspace'
                        : 'Navigate to Punch Form'}
                    </Typography>
                  </Button>
                </Link>
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: isMobile ? '16px !important' : '24px !important'
                }}
                component="div">
                {componentHeading}
              </Typography>
              {(componentHeading === 'Receiving Workspace' ||
                componentHeading === 'Manual PO Workspace' ||
                componentHeading === 'OCR PO Workspace') && (
                <Typography component="div" sx={{ float: 'right' }}>
                  <Link to={'/payment-workspace'}>
                    <Button
                      variant="outlined"
                      startIcon={<AddCardIcon style={{ fontSize: '1.5rem' }} />}
                      sx={{
                        color: '#335D87',
                        border: '0',
                        backgroundColor: '#335D871A',
                        borderRadius: '29px',
                        textTransform: 'math-auto'
                      }}>
                      <Typography sx={{ fontSize: '16px !important' }}>
                        {isMobile ? 'Payment ws' : 'Navigate to payment workspace'}
                      </Typography>
                    </Button>
                  </Link>
                </Typography>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'persistent' : 'permanent'}
        sx={{
          '& .MuiPaper-root': {
            visibility: 'visible !important'
          },
          position: 'relative'
        }}
        open={open}
        isMobile={isMobile}>
        <Box
          sx={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 300px)' }}
          {...(isMobile ? {} : { role: 'presentation' })}>
          <DrawerHeader>
            <img
              onClick={() => navigate('/home')}
              style={{ cursor: 'pointer' }}
              width={open ? '195px' : '45px'}
              src={open ? logo : CollapsedLogo}
            />
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                ...(!open && { display: 'none' }),
                backgroundColor: '#f4f4f4',
                marginLeft: '10px'
              }}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <ListItemNavButton
            {...(open
              ? {
                  sx: {
                    backgroundColor: theme.palette.primary.main,
                    ':hover': {
                      backgroundColor: theme.palette.primary.main
                    },
                    padding: '4px 6px'
                  }
                }
              : {
                  sx: {
                    border: 'none',
                    padding: '1px'
                  }
                })}>
            <img
              src={ProfileIcon}
              height={'45px'}
              style={{ borderRadius: '50%', marginRight: open ? '10px' : '1px' }}
            />
            {open && (
              <Typography component="div" sx={{ color: '#fff' }}>
                <Typography component="div" sx={{ fontSize: '12px' }}>
                  {userRole}
                </Typography>
                <Typography component="div" sx={{ fontSize: '14px' }}>
                  {userName}
                </Typography>
              </Typography>
            )}
          </ListItemNavButton>
          <Divider />

          <Typography component="div">
            <List>
              {menus.map((menu, index) =>
                menu.isNested ? (
                  <NestedNavItem menu={menu} key={index} open={open} />
                ) : (
                  <SingleNavItem key={index} menu={menu} open={open} />
                )
              )}
            </List>
          </Typography>
          <Divider />
        </Box>
        <Preference open={open} setChatModal={setChatModal} />
      </Drawer>
      <Main open={open} sx={{ marginTop: '150px' }} isMobile={isMobile}>
        {/* <PaperContainer></PaperContainer> */}
        {children}
      </Main>
      {chatModal && <ChatModal onClose={() => setChatModal(false)} />}
    </Box>
  );
};

export default DashboardLayout;
