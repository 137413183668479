import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  forwardRef,
  useImperativeHandle
} from 'react';
import type { ChangeEvent } from 'react';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import DropDown from 'components/NewLayout/Dropdown';
import {
  ALPHA_NUMERIC_DASH_REGEX,
  getValidations,
  removeNullOrEmptyValues,
  sortArrByKey
} from 'utils/index';
import type { CustomerContactIds } from 'api/types';
import type { ContactSelectType, Customer, CustomerContactItems, Project } from 'api/types';
import { getAllCustomers, postProject, updateProject } from 'api';
import Select from 'react-select';
import toast from 'react-hot-toast';
import Validator from 'simple-react-validator';
import TextField from 'components/NewLayout/Textfield';
import DrawerForm from 'components/NewLayout/DrawerForm';
import CustomButton from 'components/NewLayout/Button';
import { Checkbox, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { useAdminWorkspaceStore } from 'store/index';
import type { AdminHubChildComponentRef } from 'utils/types';
import MultiSelect from 'components/NewLayout/MultiSelect';
import Loader from 'components/NewLayout/Loader';
import CustomDialog from 'components/NewLayout/Dialog';

export interface SelectDropdownOptionType {
  id: string;
  value: string;
}

export default function CreateProject({
  open,
  setOpen,
  action
}: {
  open: boolean;
  setOpen: (status: boolean) => void;
  action: 'create' | 'update';
}) {
  const childRef = useRef<AdminHubChildComponentRef>();
  return (
    <DrawerForm
      open={open}
      closeDrawer={() => setOpen(false)}
      heading={`${action === 'create' ? 'Create' : 'Update'}  A Job`}
      actions={
        <>
          <CustomButton variant="outlined" label="Discard" onClick={() => setOpen(false)} />
          <CustomButton
            label="Submit Request"
            onClick={() => {
              if (childRef.current) {
                childRef.current.submitForm();
              }
            }}
          />
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="em" color="textSecondary">
            Enter the details below to create a new Job.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProjectForm action={action} ref={childRef} onComplete={() => setOpen(false)} />
        </Grid>
      </Grid>
    </DrawerForm>
  );
}

const initialObj = {
  project_name: '',
  customer_id: '',
  is_active: true,
  created_by: '',
  last_updated_by: '',
  created_at: '',
  last_updated_at: '',
  project_code: '',
  customer_contact_ids: [],
  appearance_order_and_logistic: true,
  appearance_time_tracting_and_attendance: true
};

const ProjectForm = forwardRef(
  ({ action, onComplete }: { action: 'update' | 'create'; onComplete: () => void }, ref) => {
    const [data, setData] = useState<Project>(initialObj);
    const [allCustomers, setAllCustomers] = useState<DropdownOptionType[]>([]);
    const [allCustomersDetails, setAllCustomersDetails] = useState<Customer[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allContacts, setAllContacts] = useState<SelectDropdownOptionType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const customerContactIdArr: string[] = [];
    const [warningMessage, setWarningMessage] = useState<string>('');
    const { messages, validators } = getValidations();
    const validator = useRef(
      new Validator({
        messages: {
          ...messages,
          size: 'Length should not exceeded 4 digits'
        },
        validators
      })
    );
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const project = useAdminWorkspaceStore((state) => state.selectedJob);
    const updateStoreJob = useAdminWorkspaceStore((state) => state.updateJob);
    const theme = useTheme();

    useEffect(() => {
      setData(project ?? initialObj);
      if (action == 'update') {
        getDropdownOptions(project?.customer_id?.toString() ?? '', true);
      }
    }, [project, allCustomersDetails]);

    useEffect(() => {
      setIsLoading(true);
      getAllCustomers()
        .then((getCustomers) => {
          const customersArr: DropdownOptionType[] = [];
          getCustomers.map((item: Customer) => {
            customersArr.push({ id: item.customer_id ?? '', value: item?.customer_name ?? '' });
          });
          setAllCustomersDetails(getCustomers);
          setAllCustomers(customersArr);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }, []);

    useEffect(() => {
      if (project) {
        setData(project);
      }
    }, [project]);

    useImperativeHandle(ref, () => ({
      submitForm() {
        if (validator.current.allValid()) {
          setIsLoading(true);
          // if (data?.customer_contact_ids?.length > 0) {
          if (action === 'create') {
            toast.promise(
              postProject(removeNullOrEmptyValues(data)).then((res) => {
                if (res.message === 'Project added successfully!') {
                  onComplete();
                }
              }),
              {
                loading: 'Adding...',
                success: 'Job added successfully!',
                error: ''
              },
              { position: 'bottom-left', success: { duration: 3000 } }
            );
          } else {
            const toastId = toast.loading('Loading...', {
              position: 'bottom-left'
            });
            toastId;
            updateProject(data, data?.project_id ?? 0)
              .then((res) => {
                if (res.message === 'Project updated successfully!') {
                  onComplete();
                  const customer = allCustomersDetails.find(
                    (a) => a.customer_id == data.customer_id
                  );
                  let customerContacts: any = [];
                  if (customer) {
                    customerContacts =
                      customer?.customercontacts?.filter((contact) =>
                        data.customer_contact_ids?.includes(contact?.customer_contact_id || '')
                      ) || [];
                  }

                  updateStoreJob({
                    ...data,
                    customerName: customer?.customer_name || '',
                    customer_contacts: customerContacts,
                    subRows: customerContacts
                  });
                }
                toast.dismiss(toastId);
                toast.success('Job updated successfully!!', {
                  position: 'bottom-left'
                });
                setIsLoading(false);
              })
              .catch((error) => {
                toast.dismiss(toastId);
              });
          }
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      }
    }));

    function getDropdownOptions(param: string, isFirstRender?: boolean) {
      const contactsArr: SelectDropdownOptionType[] = [];
      const filteredContactsArr: SelectDropdownOptionType[] = [];
      const newArray = allCustomersDetails.filter(function (el) {
        return el.customer_id == param;
      });

      newArray[0]?.customercontacts?.map((item: CustomerContactItems) => {
        contactsArr.push({
          id: item.customer_contact_id ?? '0',
          value: item.first_name ?? '' + item.last_name
        });
      });
      setAllContacts(contactsArr);
      if (isFirstRender) {
        project?.customer_contacts?.map((item: CustomerContactItems) => {
          filteredContactsArr.push({
            id: item.customer_contact_id ?? '0',
            value: item.first_name ?? '' + item.last_name
          });
        });
        setSelectedOptions(filteredContactsArr);
      }
    }

    const handleChangeMultiSelect = ({ target: { value } }) => {
      setSelectedOptions(value);
      value.map((item: any) => {
        customerContactIdArr.push(item?.id);
      });

      setData({ ...data, customer_contact_ids: customerContactIdArr });
    };

    const handleChange = ({
      target: { name, value }
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (name == 'project_name' && (!ALPHA_NUMERIC_DASH_REGEX.test(value) || value.length > 40)) {
        toast.error(
          'Special characters are not allowed and maximum length should not more then 40 characters',
          {
            position: 'bottom-left'
          }
        );
        return;
      }
      if (name == 'customer_id') {
        setAllContacts([]);
        getDropdownOptions(value);
        setSelectedOptions([]);
      }
      if (name == 'project_code' || name == 'labor_budget' || name == 'material_budget') {
        if (name == 'project_code' && value.length > 4) {
          toast.error('Length should not exceed 4 digits', {
            position: 'bottom-left'
          });
          return;
        }
        if (Number(value) < 0) {
          toast.error('Value cannot be less then 0', {
            position: 'bottom-left'
          });
          return;
        }
      }
      setData({ ...data, [name]: value === 'true' ? true : value === 'false' ? false : value });
    };

    const onChangeAppearance = (
      name: 'appearance_order_and_logistic' | 'appearance_time_tracting_and_attendance',
      value: boolean
    ) => {
      if (!value) {
        setWarningMessage(
          name === 'appearance_order_and_logistic'
            ? 'Are you sure, you want to unchecked this? This job will not be available in Order and Logistics modules.'
            : 'Are you sure, you want to unchecked this? This job will not be available in Time tracking and attendance modules.'
        );
      } else {
        setData({
          ...data,
          [name]: value
        });
      }
    };

    return (
      <>
        {' '}
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Job Name"
                name="project_name"
                placeholder="Project Name"
                value={data.project_name}
                onChange={handleChange}
                isRequired={true}
                disabled={action === 'update' && !project}
                validator={validator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                min="0"
                maxlength={4}
                labelText="Job Number"
                name="project_code"
                placeholder="Project Number"
                value={data.project_code}
                onChange={handleChange}
                isRequired={true}
                disabled={action === 'update' && !project}
                validator={validator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropDown
                label="Customer"
                inputName="customer_id"
                optionText="Select Customer Name"
                value={data.customer_id}
                required
                onChange={handleChange}
                options={sortArrByKey(allCustomers, 'value')}
                disabled={action === 'update' && !project}
                validator={validator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MultiSelect
                label="Customer Contact"
                inputName="customer_contact_ids"
                selectedOptions={selectedOptions}
                onChange={handleChangeMultiSelect}
                options={allContacts}
                disabled={action === 'update' && !project}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Material Budget"
                name="material_budget"
                placeholder="Material Budget"
                value={data.material_budget}
                onChange={handleChange}
                validator={validator}
                isRequired
                disabled={action === 'update' && !project}
                type="number"
                min="0"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Labor Budget"
                name="labor_budget"
                placeholder="Labor Budget"
                value={data.labor_budget}
                onChange={handleChange}
                validator={validator}
                isRequired
                disabled={action === 'update' && !project}
                type="number"
                min="0"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Estimated Hours"
                name="estimated_hours"
                placeholder="Estimated Hours"
                value={data.estimated_hours?.toString()}
                onChange={handleChange}
                validator={validator}
                isRequired
                disabled={action === 'update' && !project}
                type="number"
                min="0"
              />
            </Grid>
            <Grid item sm={6} />

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={({ target: { checked } }) =>
                      onChangeAppearance('appearance_time_tracting_and_attendance', checked)
                    }
                    checked={data.appearance_time_tracting_and_attendance}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.secondary.main
                  }
                }}
                label="Appears in Time Tracking & Attendance"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={({ target: { checked } }) =>
                      onChangeAppearance('appearance_order_and_logistic', checked)
                    }
                    checked={data.appearance_order_and_logistic}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.secondary.main
                  }
                }}
                label="Appears in Ordering & Logistics"
              />
            </Grid>
            {action === 'update' && (
              <Grid item xs={12}>
                <DropDown
                  label="Active Status"
                  inputName="is_active"
                  optionText="Choose..."
                  value={data?.is_active ? 'true' : 'false'}
                  onChange={handleChange}
                  options={[
                    { id: 'true', value: 'Active' },
                    { id: 'false', value: 'Inactive' }
                  ]}
                  disabled={action === 'update' && !project}
                />
              </Grid>
            )}
          </Grid>
        )}
        <CustomDialog
          title={'Confirmation'}
          maxWidth="xs"
          open={warningMessage ? true : false}
          closeDialog={() => setWarningMessage('')}
          content={<Typography>{warningMessage}</Typography>}
          actions={
            <>
              <CustomButton
                onClick={() => setWarningMessage('')}
                variant="outlined"
                label="Cancel"
              />
              <CustomButton
                onClick={() => {
                  let name = '';
                  if (warningMessage.includes('Time tracking and attendance')) {
                    name = 'appearance_time_tracting_and_attendance';
                  } else {
                    name = 'appearance_order_and_logistic';
                  }
                  setWarningMessage('');
                  setData({
                    ...data,
                    [name]: false
                  });
                }}
                label={'Confirm'}
              />
            </>
          }
        />
      </>
    );
  }
);
ProjectForm.displayName = 'ProjectForm';
