import DashboardLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { usePermissionStore } from 'store';
import { rolesPermssions } from 'store/permissionSlice';

export default function ProtectedRoute() {
  const addPermissions = usePermissionStore((state) => state.addPermissions);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      addPermissions(rolesPermssions);
    }
  }, []);

  return localStorage.getItem('token') ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to={'/home'} />
  );
}
