import React from 'react';
import Can from 'components/Can';
import { getQBAlerts } from 'api/adminHub';
import { useAdminWorkspaceStore } from 'store/index';
import { useQuery } from '@tanstack/react-query';
import CustomButton from 'components/NewLayout/Button';
import { Add } from '@mui/icons-material';
import { SvgIcon, Badge, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import QbIcon from 'assets/images/qb-icon.svg';

const modules = {
  Jobs: ['CREATE_JOB', 'UPDATE_JOB'],
  Vendors: ['CREATE_VENDOR', 'UPDATE_VENDOR'],
  Customers: ['CREATE_CUSTOMER', 'UPDATE_CUSTOMER']
};

const CustomBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    backgroundColor: '#D81B60',
    color: 'white',
    borderRadius: '20px',
    // width: 15,
    // height: 16,
    padding: '3px 6px',
    top: 2,
    right: 22
  },
  '& img': {
    marginRight: '20px',
    cursor: 'pointer'
  }
});
export default function Heading({
  workspace,
  onClickCreate,
  onClickQBAlerts
}: {
  workspace: string;
  onClickCreate: () => void;
  onClickQBAlerts?: () => void;
}) {
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const setQBAlerts = useAdminWorkspaceStore((state) => state.setQBAlerts);

  const module = workspace.includes('creditcard')
    ? 'CreditCards'
    : workspace.includes('people')
    ? 'Users'
    : workspace.includes('job')
    ? 'Jobs'
    : workspace.includes('vehicle')
    ? 'Vehicles'
    : workspace.includes('customer')
    ? 'Customers'
    : 'Vendors';

  const ws = workspace.split('-')[0];
  const path = window.location.pathname.split('/');
  const isQBWorkspace = ['Jobs', 'Vendors', 'Customers'].includes(module);

  if (isQBWorkspace) {
    useQuery({
      queryKey: ['alerts'],
      queryFn: getQBAlerts,
      staleTime: 5000,
      onSuccess: (alerts) => {
        setQBAlerts(alerts);
      },
      onError: (error) => {
        console.log(error);
      }
    });
  }

  const alerts = qbAlerts.filter((a) => modules[module]?.includes(a.action_type));

  return (
    <>
      {path.length === 3 && (
        <div className="actions">
          {isQBWorkspace && alerts?.length > 0 && (
            <Can I="read" module="QuickBooksAlert">
              <QBBadge
                count={alerts.length}
                onClickQBAlerts={() => onClickQBAlerts && onClickQBAlerts()}
              />
            </Can>
          )}

          <Can I="create" module={module}>
            <CustomButton
              onClick={() => onClickCreate()}
              label={
                <>
                  <SvgIcon
                    sx={{
                      border: '1px solid #eee',
                      borderRadius: '50%',
                      marginRight: '10px',
                      width: '20px',
                      height: '20px'
                    }}
                    component={Add}
                  />
                  {`Create ${
                    ws === 'people' ? 'person' : ws === 'creditcard' ? 'credit card' : ws
                  }`}{' '}
                </>
              }
            />
          </Can>
        </div>
      )}
    </>
  );
}

export const QBBadge = ({
  count,
  onClickQBAlerts
}: {
  count: number;
  onClickQBAlerts: () => void;
}) => (
  <Tooltip title="QB Sync Alerts">
    <CustomBadge badgeContent={count || 0}>
      <img src={QbIcon} width={40} height={40} onClick={() => onClickQBAlerts()} />
    </CustomBadge>
  </Tooltip>
);
